<template>
  <section class="deposit-usdc-confirmation">
    
    <!-- Confirm -->
    <template v-if="!waitingTransfer">
      <div>
        <h1 class="headline-small mb-s40" v-text="$t('transfer_usdc.deposit_usdc')" />
        <p v-text="$t('transfer_usdc.deposit_warning')" />
        
        <div class="flex justify-between items-center mt-s16 mb-s40">
          <div class="pr-s24">
            <p class="body-text-large" v-text="$t('transfer_usdc.dibbs_wallet', { chain: selected_deposit_chain })" />
            <p class="break-all subheadline-small"  ref="chainAddress" v-text="chainAddress" />
          </div>
          <div>
            <ButtonV2 
              :label="$t('transfer_usdc.copy')"
              testId="btn-copy"
              size="small"
              version="secondary"
              @onClick="copyAddress"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <ButtonV2
          class="mb-s16" 
          :label="$t('transfer_usdc.agree')"
          testId="btn-agree"
          size="medium"
          version="primary"
          @onClick="confirmTransfer"
        />
        <ButtonV2 
          :label="$t('transfer_usdc.go_back')"
          testId="btn-goBack"
          size="medium"
          version="secondary"
          @onClick="goBack"
        />
      </div>
    </template>

    <!-- Waiting -->
    <template v-else>
      <div>
        <h1 class="headline-small mb-s24" v-text="$t('transfer_usdc.waiting_transfer')" />
        <p v-text="$t('transfer_usdc.notify_transfer')" />
      </div>
      <div class="flex flex-col mt-s40 mb-s16">
        <ButtonV2 
          :label="$t('transfer_usdc.view_pendings')"
          testId="btn-viewPendings"
          size="medium"
          version="primary"
          @onClick="goToTransactions"
        />
      </div>
    </template>
    
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ButtonV2 } from '@/components/misc';
import copyToClipboard from '@/utils/clipboard';

export default {
  name: 'DepositUsdcConfirmation',
  components: { ButtonV2 },
  data() {
    return {
      waitingTransfer: false,
    };
  },
  computed: {
    ...mapState('wallet', [
      'ongoingDepositData',
      'selected_deposit_chain',
      'circle_create_chain_deposit_address',
    ]),
    
    chainAddress() {
      if(!this.circle_create_chain_deposit_address) {return '';}
      return this.circle_create_chain_deposit_address.address;
    },
  },
  methods: {
    ...mapActions('wallet', [
      'resetUsdcDeposit',
    ]),
    copyAddress() {
      try {
        copyToClipboard(this.circle_create_chain_deposit_address.address);
      } catch (err) {
        // that's fine
      }
    },
    async confirmTransfer() {
      this.waitingTransfer = true;
      await this.$store.dispatch('events/track', {
        event: 'TRANSFER_USDC_CHAIN_ALERT',
      });
    },
    goToTransactions() {
      this.resetUsdcDeposit();
      this.goToInternalPageGlobal('/wallet/transaction_history_all');
      this.hideModal();
    },
    goBack() {
      this.showModal('DepositUsdc', { amount: this.lodashGet(this.ongoingDepositData, 'amount', 0) });
    },
  },
};
</script>

<style scoped>
.deposit-usdc-confirmation {
  @apply flex flex-col justify-between;
  max-width: 440px;
}
</style>
